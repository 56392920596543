<template>
    <o-dropdown ref="dropdown" :target-ref="virtualTarget" virtual :teleportTargetOverride="teleportTargetOverride">
        <template #dropdown="scope">
            <div :ref="scope.container">
                <div class="shadow dropdown-menu card" style="{ z-index: 100; opacity: '1'; width: 300px;}" :style="teleportTargetOverride ? 'z-index: 200' : ''">

                    <div v-for="button in buttonsArray" :key="button.label">
                        <div v-if="button?.isInput">
                            <div class="overlay-dropdown-item">
                                {{ button.label }}{{ getButtonTitle(button) }}
                                <div class="px-0 mb-2 mt-2" :class="getButtonClass(button)" style="position: relative; z-index: 101;"  :style="{ opacity: '1' }">
                                    <input  
                                        :type="button?.inputType" 
                                        :class="button?.inputClass" 
                                        :style="{background: button.inputClass === 'slider' ? vSlideColor(button) : ''}" 
                                        :min="button?.minValue" :max="button?.maxValue" :step="button?.stepValue" 
                                        :value="row[button.field]" 
                                        v-model="row[button.field]">
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <button class="dropdown-item" @click="handleButtonClick(button)">
                                <i class="bi me-1" :class="button.icon" v-if="button.icon"></i>
                                {{ button.label }}{{ getButtonTitle(button) }}
                            </button>
                        </div>
                        <div class="dropdown-divider"></div>
                    </div>

                </div>
            </div>
        </template>
    </o-dropdown>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';

const props = defineProps<{
    coorX: number,
    coorY: number,
    row?: object,
    buttons?: object
}>();

const cX = ref(props.coorX);
const cY = ref(props.coorY);
const targetRow = ref(null);
const rowIndex = ref(null);
const column = ref(null);
const linkValue = ref(null);
const sliderColor = 'steelblue';
const rangeColor = 'lightsteelblue';
const dropdown = ref<null|any>(null);
const teleportTargetOverride = ref(null);

const emit = defineEmits(['open-component'])

const buttonsArray = computed(() => {
    const newArray = Array.from(props.buttons?.values());
    return newArray;
});

function getButtonClass(button) {
    if(button.inputClass == 'slider'){
        return 'slider-container';
    }
    return;
}

function getButtonTitle(button) {
    const field = props?.row[button?.field]

    if (button.format && !button.hideField) {
        return button.fornmat = '%' ? ': ' + (field*100).toFixed(0) + button.format : ': ' + field + button?.fornmat
    }
    if (button.hideField || button.field == null) {
        return;
    }
    return button?.field
    
}

function handleButtonClick(button) {
    if(button.component) {
        emit('open-component', button.component);
    }
    return;
}

function setLocation(x: number, y: number) {
    cX.value = x;
    cY.value = y;
}

function initItemValues(options: {
    event?: MouseEvent,
    targetRow: any,
    column: any
    rowIndex?: number
}) {
    targetRow.value = options.targetRow;
    column.value = options.column;
    linkValue.value = null;
    if (options.event) {
        const closest = options.event?.target?.closest('a');
        if (closest) {
            linkValue.value = closest.href;
        }
    }
    if (options.hasOwnProperty('rowIndex')) {
        rowIndex.value = parseInt(options.rowIndex);
    }
}

const virtualTarget = computed(() => {
    return {
        getBoundingClientRect: () => ({
            width: 0,
            height: 0,
            top: cY.value,
            right: cX.value,
            bottom: cY.value,
            left: cX.value,
        })
    };
});

function vSlideColor(button) {
    const activityProgress = props?.row[button?.field]
    const progressPercentage = activityProgress * 100; 
    return `linear-gradient(
        to right,
        ${sliderColor} 0%,
        ${sliderColor} ${progressPercentage}%,
        ${rangeColor} ${progressPercentage}%,
        ${rangeColor} 100%
    )`;
}

onMounted(() => {
    const modal = dropdown.value?.$el?.parentElement?.closest('.modal-content') ?? dropdown.value?.$el?.parentElement?.closest('.modal');
    if (modal) {
        teleportTargetOverride.value = modal;
    }
    dropdown.value.open()
});

defineExpose({ dropdown, setLocation, initItemValues });
</script>

<style>
.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.overlay-dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: rgba(0,0,0,0);
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius, 0);
}


</style>
